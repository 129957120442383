import {useState} from "react";

const Project = (prop) => {
    const [readAll,setReadAll] = useState(true);
    return (
            <article className="tour-card" key={prop.id}>
                <div className="tour-img-container">
                    <img src={prop.image} className="tour-img" alt=""/>
                    <p className="tour-date">{prop.date}</p>
                </div>
                <div className="tour-info">
                    <div className="tour-title">
                        <h4>{prop.title}</h4>
                    </div>
                    <p>
                        {readAll ? prop.info.substring(0,300) + "..." : prop.info}
                        <button className={'info-btn'} onClick={() => setReadAll(!readAll)}>
                            {readAll ? 'show more' : 'show less'}
                        </button>
                    </p>
                </div>
            </article>
    )
}
export default Project;