import {projects} from "../data";
import Project from "./Project";
import Title from "./Title";

const Projects = () => {

    return (
        <section className="section" id="projects">
            <Title title='my ' subTitle='projects'/>

            <div className="section-center featured-center">
                {projects.map((project) => {
                    return <Project key={project.id} {...project}/>
                })}
            </div>
        </section>
    )
}
export default Projects;