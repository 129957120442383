import discover from './images/Discover.png'
import tire from './images/Tiresense.png'
import emr from './images/EMR.png'
// import tour4 from './images/tour-4.jpeg'
// import tour5 from './images/tour-5.jpeg'
// import tour6 from './images/tour-6.jpeg'

export const pageLinks = [
    {id: 1, href: '#home', text: 'home'},
    {id: 2, href: '#about', text: 'about'},
    {id: 3, href: '#services', text: 'services'},
    {id: 4, href: '#projects', text: 'projects'},
];

export const socialLinks = [
    {id:1,href:"https://www.linkedin.com/in/rafaelpolanski/",icon:'fab fa-linkedin-in'},
];

export const services = [
    {id: 1, icon: "fa-brands fa-uncharted", title: 'Product Management', text: 'Discover the desirability, feasibility, and viability of new business models'},
    {id: 2, icon: "fa-brands fa-searchengin", title: 'Engineering Management', text: 'Create a high performing and satisfied team, focusing on high throughput'},
    {id: 3, icon: "fa-solid fa-pen-clip", title: 'Design Thinking', text: 'Problem solving, hypothesis validation, and ideation'},
    {id: 4, icon: "fa-solid fa-robot", title: 'Machine Learning', text: 'Predict the future based on the past'},
]

export const projects = [
    {id: 1, image: tire, date: '2018-2020', title: 'Tire Sensor',
        info: 'As part of ExxonMobil Chemical Innovation Hub, through interviews with senior leadership, one of the key pain points identified is that although ExxonMobil has a strong relationship with Tire Manufacturers across the globe with the Butyl offer, a product required for Tire Manufacturement, there was still a needed to strenghten the relationship with important clients, such as Goodyear, Bridgestone.\n' +
            '\n' +
            'Such problem to be solved increased our curiosity on how much drivers consider their tire health for the safety of their families, as well as for fuel saving. We wanted to learn more about consumers behaviors, as well as explore the opportunity to explore potential new business models and revenue streams.\n' +
                '\n' +
            'After many interviews, and with the premise that tire manufactures want to increase their sales, we came with the following problems hypotheses to be explored:\n'+
            '\n' +
            '- Drivers would be willing to buy new tires if they knew there was a need\n' +
            '\n' +
            '- Tire manufactures are interested about their supply chain needs across the globe\n' +
            '\n' +
            '- A platform connecting driver\'s needs could be the opportunity ExxonMobil Chemical could approach Tire Manufacturers\n' +
            '\n' +
            '- Passenger vehicles represent a good way to explore the business model with initial low investment\n' +
            '\n' +
            '- Heavy duty represent the bigger opportunity\n' +
            '\n' +
            '\n' +
            'We decided to pursue two approaches, testing the viability of a business model called \'flow-through marketing\', installing a tire reader on a gas station and providing vouchers with local tire dealers for drivers willing to experiment on our prototype. The reader could measure the tire wear, pressure, license plate, and read the tire\'s DOT, which is a code that represent the tire family.\n' +
            '\n' +
            'Tire manufacturers were also called to inspect the product first hand and the experimentation was a huge success. From a technical standpoint, while the tire reader was scanning the vehicle, it was generating a JSON file to our servers. A Python script was consuming the data and populating an embelished website, fully built with React.\n' +
            '\n' +
            'Drivers creating user accounts to the front end, informing their make, model, and mileage would be granted vouchers with discounts and our data engineering team would start to have something very important moving forward: The driver\'s behavior!\n' +
            'Some challenges emerged during the implementation, including the fact that ExxonMobil is not a product manufacturer and we would need to pursue partnerships to turn this business model into a reality. Still, we were just scratching the tip of the iceberg when exploring the business model, and as with all innovations, there was a need to turn this solution into revenue. I might add that was the case during the pandemic as well.\n' +
            '\n' +
            '\n' +
            'Apart from the IoT (Internet of Things) concept with the Tire Reader, having information across the globe around drivers behaviors, their type of vehicles, and the tire families, in conjunction with weather in respective cities would allow us to understand data needs and provide such information to our key clients, the tire manufacturers. Our ambitions were to create RESTful APIs to feed this information and then, apply predictive analytics and machine learning to help tire manufacturers with their logistics. This initiative did not only achieve our so desired goals to strenghten the relationship with tire manufacturers, but also planted the seed to carve out new revenue streams through innovation.\n' +
            '\n' +
            'I am really proud for being the Innovation Project Manager for the Tire Sensor initiative. It helped me gauge my understanding around executives\' expectations when it comes to the creation of new business model and revenue streams.\n' +
            '\n' +
            'While I could talk much more about Tire Sensor, this is probably better over a cup of coffee. Shall we have one?'},
    {id: 2, image: emr, date: '2020-2021', title: 'ExxonMobil Rewards+',
        info: 'The ExxonMobil Rewards+ App for United States, and SpeedPass+ for Canada are Consumer Face apps, being a huge opportunity I had to focus on B2C. The value proposition of the Apps is to allow drivers to find the closest Exxon, Mobil, or Imperial Oil Gas Stations, allowing users to pay from the convenience of their vehicles, utilizing Apple Pay, Google Pay or their onboarded credit cards. On top of that, the apps are a huge opportunity to raise the partnership with Gas Stations and their convenience stores. We wanted to leverage loyalty points and allow consumers to exchange those points for products at the convenience stores. As such opportunities arose, we ideated on how great it could be if on top of the loyalty points and the station finder, users could also have access to the highlighted convenience store offers for their loyalty points exchange. This is what we defined as our opportunity to continue to put consumers at the heart of what we did with our product management.\n' +
            '\n' +
            'Interestingly enough, as you manage a consumer facing product, you will end up facing other challenges. We needed to have internal debates whether the accessibility considerations for people with disabilities were sufficient. Also, user experience, being very important was also taken into consideration. Finally, we needed to look into our metrics, looking into important KPIs such as growth, retention, acquisition and gross margin. Those types of debates made us look into the need to grow our user base and find tactical solutions to make it happen. This is how we ended up looking into partnerships and AARP sounded to be a very good one to help communities to adopt ExxonMobil Rewards+ as their app of choice for gas needs.\n' +
            '\n' +
            '\n' +
            'One may ask how to foster innovation with a competitive environment, seeking the blue ocean when many Oil and Gas companies also developed their own apps. While ExxonMobil Rewards was the first, we acknowledge competititors emerged with that market and studying the types of competition, we know that reliability and convenience are very important for customers. Having said that we raised our partnership with Apple to be one of the first companies to implement the new concept called AppClips. It was announced at WWDC and implemented by Apple as part of IOS 14, allowing companies to create lighter versions of their mobile apps, providing a flavor of the user experience to users and giving the choice to customers to install the full version based on their needs. The opportunity to work with Apple on their new innovation was an amazing experience, where ExxonMobil decided to have AppClips \' NFC Tags \' on their gas stations for users to install these light versions and experiment on.\n' +
            'We also have a strong user base not utilizing Apple Devices, and similarly, we wanted to explore with Google on their NFC offer as well. We spent months with Google focusing on ways to have ExxonMobil Rewards being able to scan Google\'s NFC tag for maximized throughput for Android devices as well. Partnerships with Apple and Google certainly helped with the convenience and reliability we needed to have our consumers looking into our mobile products as the best market references, but still, we could never rest without another question: Could we innovate more? The answer is yes, we haven\'t talked with Amazon yet.\n' +
            '\n' +
            '\n' +
            'Amazon, with a strong offer around Alexa could be a great opportunity to help with convenience, as well as accessibility. There are people with disabilities relying on audio services and AI, and we knew Amazon\'s Alexa could be a great opportunity to explore. We wanted to simply come out with a nice and concise request to Alexa, such as "Alexa, pay for Fuel", and see the magic happen. We ended up concluding that implementation and this was a huge opportunity to focus on the advertisements of our products to the public.\n' +
            '\n' +
            'Those were two years focusing on this amazing opportunity to impact the lives of over 30 million of users in the United States and Canada. It was a great responsibility, but also, it made me humble enough to know while it is easy to find reasons to celebrate, the impact is proportional to the number of users, therefore, actions need care, love, and passion for our customers. It is more than vehicles, or gasoline. People could need their vehicles back on track immediately to take care of their loved ones! With IT, we are here to make people\'s lives convenient and they count on our digital offers to live their lives.'},
    {id: 3, image: discover, date: '2023-2024', title: 'Discover',
        info: 'Heath does an amazing job, keeping communities safe not only in the US, but also manufacturing the technology required for gas inspections overseas. When I think about Heath, caring for the staff and their safety, establishing a strong partnership with Utility Companies to fulfill their quality standards for inspections, as well as the full focus on innovation with the software, it is one of the aspects of my career that made feel really proud. Heath has the tradition to be the most reliable gas inspection services company in the United States, and my participation there focuses on a very important aspect in today\'s world - The digital transformation!\n' +
            '\n' +
            'Within Heath, I am a Lead Product Manager focusing on different types of technologies, which include:\n' +
            '\n' +
            '- Customer\'s Mobile Apps to integrate with Heath\'s Best in Class Hardware Devices as an IOT offer for proof of inspections and calibrations\n' +
            '\n' +
            '- A Mobile App that was made for Technicians so that they can inspect and feed inspection data to the cloud, operating online or offline for remote regions\n' +
            '\n' +
            '- A Web Portal, allowing Heath\'s Supervisors, as well as Customers to follow near real time information around inspections. It also allows Heath to plan the inspection process according to the right priorities and map sheets established in given locations and requested by the customer\n' +
            '\n' +
            '- A desktop app utilized for Discover, a vehicle equipped with Heath\'s latest technology and ensuring a combined strategy and offer for foot inspection and mobile inspection\n' +
            '\n' +
            '\n' +
            'Out of many opportunities to continue to support Heath, we interview users and customers, synthesizing their pain points and ideating for what would be a game changer for the business, putting the users at the heart of what we do! One of the key features being developed is the new customer dashboard, allowing a full analysis around number of leaks identified in a given location, whether it is underground, overground, the footage covered in a given area, and additional information that allows customers to have a good decision-making around the inspections and how to orchestrate communication back to state committees.\n' +
            'When you work with software encompassing mobile, web, and desktop, for an interesting industry, this already sounds to be amazing, but when on top of all of that, you also have the chance to explore data visualization on top of maps, on a very similar fashion than what Google Earth does, focusing on KML files, it becomes even more interesting.\n' +
            '\n' +
            '\n' +
            'At the university, when studying computer science, sometimes students fail to understand the importance of graphs. When working with maps, when there is a need to calculate distances, utilize assets for visualization, as well as breadcrumbs showing where a given technician or vehicle is performing inspections, then it makes it easier to understand why to fully leverage those types of algorithms for modern technology. This is today\'s reason on why those types of advanced algorithms are so necessary.\n' +
            '\n' +
            'Heath is a successful case it does stand out as one of my best career opportunities!\n' +
            '\n' +
            'Let me know if you want to hear more!'},
]


