import Title from "./Title";
import {services} from "../data";
import { Link } from 'react-router-dom';

const Services = () =>{
    return (
        <section className="section services" id="services">
            <Title title='my' subTitle='services'/>
            <div className="section-center services-center">
                {services.map((service) => {
                    return (
                        <div key={service.id}>
                            <article className="service" >
                                <span className="service-icon"><i className={service.icon}></i></span>
                                <div className="service-info">
                                    <h4 className="service-title">{service.title}</h4>
                                    <p className="service-text">
                                        {service.text}
                                    </p>
                                </div>
                            </article>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}
export default Services